@font-face {
  font-family: 'RF Dewi Expanded';
  src: url("../fonts/RFDewiExpanded-Regular.eot");
  src: local('RF Dewi Expanded Regular'), local('RFDewiExpanded-Regular'), url("../fonts/RFDewiExpanded-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/RFDewiExpanded-Regular.woff2") format('woff2'), url("../fonts/RFDewiExpanded-Regular.woff") format('woff'), url("../fonts/RFDewiExpanded-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RF Dewi Expanded Sbold';
  src: url("../fonts/RFDewiExpanded-Semibold.eot");
  src: local('RF Dewi Expanded Semibold'), local('RFDewiExpanded-Semibold'), url("../fonts/RFDewiExpanded-Semibold.eot?#iefix") format('embedded-opentype'), url("../fonts/RFDewiExpanded-Semibold.woff2") format('woff2'), url("../fonts/RFDewiExpanded-Semibold.woff") format('woff'), url("../fonts/RFDewiExpanded-Semibold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}
/*  <general>  */
html {
  font-size: 62.5%;
  cursor: default;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: #272727;
  font: 1.5rem/1.73 'Montserrat', sans-serif;
  font-weight: 500;
  background-color: #fff;
}
:-moz-placeholder {
  opacity: 1;
  color: currentColor;
}
:-ms-input-placeholder {
  opacity: 1;
  color: currentColor;
}
a {
  color: #2bbde7;
  font: inherit;
  text-decoration: none;
  transition: all 0.2s;
}
a:hover {
  text-decoration: underline;
}
a.lined {
  text-decoration: underline;
}
a.lined:hover {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.align-center {
  text-align: center;
}
/*  </general>  */
#HTMLBlock {
  background: #fff;
  padding: 6px;
  overflow: hidden;
  position: relative;
/*&:after {
			content ''
			display block
			position: absolute;
			width 100%
			height 100%
			background url("../i/d/1.jpg") center top no-repeat
			opacity 0.5
			left 0
			top 0
		}*/
}
#HTMLBlock.formPage {
  padding-left: 0;
  padding-right: 0;
  min-height: 100vh;
  background: #0f2b70;
}
#HTMLBlock.formPage .top {
  border-radius: 0;
}
#HTMLBlock.formPage .top .inner .header {
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  #HTMLBlock.formPage .top .inner .header {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1024px) {
  #HTMLBlock.formPage .top .inner .header {
    margin-bottom: 54px;
  }
}
@media screen and (min-width: 1280px) {
  #HTMLBlock.formPage .top .inner .header {
    margin-bottom: 64px;
  }
}
#HTMLBlock.thanksPage {
  padding-left: 0;
  padding-right: 0;
  min-height: 100vh;
  background: #0f2b70;
}
#HTMLBlock.thanksPage_text {
  padding-top: 100px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  #HTMLBlock.thanksPage_text {
    text-align: left;
  }
}
#HTMLBlock.thanksPage .top {
  border-radius: 0;
}
#HTMLBlock.thanksPage .top .inner .header {
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  #HTMLBlock.thanksPage .top .inner .header {
    margin-bottom: 150px;
  }
}
@media screen and (min-width: 1024px) {
  #HTMLBlock.thanksPage .top .inner .header {
    margin-bottom: 220px;
  }
}
@media screen and (min-width: 1280px) {
  #HTMLBlock.thanksPage .top .inner .header {
    margin-bottom: 180px;
  }
}
#HTMLBlock.thanksPage .top .inner:after {
  margin-top: 30px;
  content: '';
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 71.42857142857143%;
  background: url("../i/topBgM.svg") center center no-repeat;
  background-size: 100% auto;
}
@media screen and (min-width: 768px) {
  #HTMLBlock.thanksPage .top .inner:after {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  #HTMLBlock {
    padding: 15px;
  }
}
.main {
  margin: 0 auto;
  max-width: 1750px;
}
@media screen and (min-width: 1024px) {
  .main {
    max-width: 100%;
  }
}
.top {
  border-radius: 15px;
  min-height: 500px;
  color: #fff;
  padding: 30px 10px 50px;
  background: #0f2b70;
}
.top .inner {
  position: relative;
  margin: 0 auto;
  max-width: 420px;
}
.top .inner .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 0px;
}
.top .inner .header .logo {
  display: block;
  background: url("../i/logo.svg") center center no-repeat;
  background-size: auto 100%;
  min-height: 40px;
  font-size: 0;
  width: 130px;
}
@media screen and (min-width: 768px) {
  .top .inner .header .logo {
    width: 170px;
    min-height: 54px;
  }
}
.top .inner .header .phone {
  font-size: 1.5rem;
  font-weight: 600;
}
.top .inner .header .phone span {
  color: #8597c6;
}
@media screen and (min-width: 768px) {
  .top .inner .header .phone {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .top .inner .header {
    margin-bottom: 180px;
  }
}
@media screen and (min-width: 1024px) {
  .top .inner .header {
    margin-bottom: 140px;
  }
}
@media screen and (min-width: 768px) {
  .top .inner {
    max-width: 860px;
  }
}
@media screen and (min-width: 1280px) {
  .top .inner {
    max-width: 1160px;
  }
}
.top_body-h1 span {
  color: #f9d323;
}
.top_body-h1 .customTopSelect {
  display: inline-block;
  margin-right: 20px;
  position: relative;
}
.top_body-h1 .customTopSelect:after {
  position: absolute;
  right: -15px;
  top: 50%;
  margin-top: 0px;
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #f9d323;
}
@media screen and (min-width: 768px) {
  .top_body-h1 .customTopSelect:after {
    right: -20px;
  }
}
.top_body-h1 .customTopSelect select {
  background: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  color: #f9d323;
  padding: 0 10px;
  margin: 0 -10px;
  cursor: pointer;
  line-height: inherit;
}
.top_body-h1 .customTopSelect select option {
  font-size: 1.4rem;
  color: #000;
  display: block;
  font-weight: normal;
}
.top_body-h1 .customTopSelect select::-ms-expand {
/* for IE 11 */
  display: none;
}
.top_body-h1 .customTopSelect select:hover,
.top_body-h1 .customTopSelect select:focus {
  border: 0;
  outline: none;
}
.top_body-h1 .customTopSelect_value {
  color: #f9d323;
  text-decoration: none;
  border-bottom: 1px solid #f9d323;
  cursor: pointer;
}
.top_body-h1 .customTopSelect:hover .customTopSelect_value {
  border-bottom: 1px solid transparent;
}
.top_body-h1 .customTopSelect ul {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 1.2rem;
  background: #fff;
  z-index: 3;
  margin: 0;
  padding: 10px 0;
  border-radius: 15px;
  overflow: hidden;
}
.top_body-h1 .customTopSelect ul li {
  padding: 5px 40px 5px 20px;
  margin: 0;
  list-style: none;
  white-space: nowrap;
  color: #000;
}
.top_body-h1 .customTopSelect ul li:hover {
  background: #f9d323;
  cursor: pointer;
}
.top_body-h1 .customTopSelect ul li[data-selected] {
  background: #f9d323;
}
@media screen and (min-width: 414px) {
  .top_body-h1 .customTopSelect ul {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 768px) {
  .top_body-h1 .customTopSelect ul {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 768px) {
  .top_body-h1 {
    font-size: 2.4rem;
  }
}
@media screen and (min-width: 1280px) {
  .top_body-h1 {
    font-size: 3.4rem;
  }
}
.top_body-h2 {
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .top_body-h2 {
    padding-top: 60px;
  }
}
.top_body-form {
  padding-top: 20px;
}
.top_body-form form .customSelectWrapper {
  box-shadow: 0px 30px 40px -10px rgba(0,0,0,0.25);
}
.top_body-form form .customSelectWrapper .customSelect {
  width: 100%;
  background: #fff;
  padding: 16px 20px;
  color: #000;
  cursor: pointer;
  position: relative;
/*&:after {
								content ''
								display block
								background url("../i/union.svg") center center no-repeat
								width 17px
								height 10px
								position: absolute;
								right 6%
								top 50%
								margin-top -5px
							}*/
/*input:focus + label {
								font-size 50%
								margin-top -15px
							}*/
}
.top_body-form form .customSelectWrapper .customSelect input {
  padding: 0;
  cursor: pointer;
  position: relative;
  color: #000;
  width: 100%;
  height: 100%;
  vertical-align: top;
  z-index: 1;
  border: 0;
  font-weight: bold;
}
.top_body-form form .customSelectWrapper .customSelect input::placeholder {
  color: #000;
}
.top_body-form form .customSelectWrapper .customSelect.customSelectFrom {
  border-radius: 15px 15px 0 0;
  border-bottom: 1px solid #c4c4c4;
}
@media screen and (min-width: 768px) {
  .top_body-form form .customSelectWrapper .customSelect.customSelectFrom {
    border-radius: 15px 0 0 15px;
    border-bottom: 0;
    border-right: 1px solid #c4c4c4;
  }
}
.top_body-form form .customSelectWrapper .customSelect.customSelectTo {
  border-radius: 0 0 10px 10px;
}
@media screen and (min-width: 768px) {
  .top_body-form form .customSelectWrapper .customSelect.customSelectTo {
    border-radius: 0 15px 15px 0;
  }
}
.top_body-form form .customSelectWrapper .customSelect > div label {
  opacity: 0.5;
  margin-right: 6px;
}
@media screen and (min-width: 1024px) {
  .top_body-form form .customSelectWrapper .customSelect > div label {
    display: block;
  }
}
@media screen and (min-width: 1280px) {
  .top_body-form form .customSelectWrapper .customSelect > div label {
    display: inline-block;
  }
}
.top_body-form form .customSelectWrapper .customSelect > div span {
  display: block;
  position: relative;
  font-weight: 600;
/*label {
										position: absolute;
										left 0
										z-index: 2;
										transition all ease 200ms
									}*/
}
@media screen and (min-width: 1280px) {
  .top_body-form form .customSelectWrapper .customSelect > div span {
    display: inline-block;
  }
}
@media screen and (min-width: 1280px) {
  .top_body-form form .customSelectWrapper .customSelect > div {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .top_body-form form .customSelectWrapper .customSelect {
    width: 50%;
    padding: 36px 20px;
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 1024px) {
  .top_body-form form .customSelectWrapper .customSelect {
    font-size: 2rem;
    line-height: 1.4;
    padding: 22px 30px;
  }
}
@media screen and (min-width: 1280px) {
  .top_body-form form .customSelectWrapper .customSelect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 36px 30px;
  }
}
@media screen and (min-width: 768px) {
  .top_body-form form .customSelectWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .top_body-form form .customSelectWrapper {
    width: 75%;
    padding-right: 10px;
  }
}
.top_body-form form:after {
  margin-top: 30px;
  content: '';
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 71.42857142857143%;
  background: url("../i/topBgM.svg") center center no-repeat;
  background-size: 100% auto;
}
@media screen and (min-width: 768px) {
  .top_body-form form:after {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .top_body-form form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}
.top_body-form_btn {
  padding-top: 10px;
  text-align: center;
  box-shadow: 0px 30px 40px -10px rgba(0,0,0,0.25);
}
@media screen and (min-width: 768px) {
  .top_body-form_btn {
    padding-top: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .top_body-form_btn {
    padding-top: 0px;
  }
}
@media screen and (min-width: 1024px) {
  .top_body-form {
    padding-top: 40px;
  }
}
.top_body-h3 {
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .top_body-h3 {
    padding-top: 140px;
  }
}
.top_body-items {
  padding-top: 30px;
}
@media screen and (min-width: 768px) {
  .top_body-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;
  }
}
.top_body-item {
  font-size: 1.3rem;
  margin-top: 15px;
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 15px;
}
.top_body-item > div {
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 1.6rem;
  line-height: 1.2;
  color: #f9d323;
  position: relative;
  padding-right: 30px;
}
.top_body-item > div:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 7px solid #f9d323;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -4px;
  transition: all ease 400ms;
}
.top_body-item > div br {
  display: none;
}
@media screen and (min-width: 768px) {
  .top_body-item > div {
    font-size: 2.4rem;
    padding-right: 0px;
  }
  .top_body-item > div:after {
    display: none;
  }
}
.top_body-item p {
  display: none;
}
@media screen and (min-width: 768px) {
  .top_body-item p {
    display: block;
  }
}
.top_body-item.active > div {
  color: #fff;
}
.top_body-item.active > div:after {
  border-top-color: #fff;
  transform: rotate(180deg);
}
@media screen and (min-width: 768px) {
  .top_body-item.active > div {
    color: inherit;
  }
}
.top_body-item.active p {
  display: block;
}
.top_body-item:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}
@media screen and (min-width: 768px) {
  .top_body-item {
    font-size: 1.5rem;
    width: 50%;
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 1280px) {
  .top_body-item {
    width: 260px;
  }
}
.top .formBlock {
  padding: 10px 10px 30px;
  background: #fff;
  border-radius: 15px;
  max-width: 1430px;
  margin: 0 auto;
}
.top .formBlock-steps {
  color: #1d1d1d;
  font-family: 'RF Dewi Expanded';
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 20px;
}
.top .formBlock-steps span {
  color: #b4b4b4;
}
.top .formBlock-steps span:before {
  content: '';
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 4px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background: #fff;
  border: 1px solid #b4b4b4;
}
@media screen and (min-width: 768px) {
  .top .formBlock-steps span:before {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
.top .formBlock-steps span.active {
  color: #1d1d1d;
}
.top .formBlock-steps span.active:before {
  border-color: transparent;
  background: #f4d34f;
}
.top .formBlock-steps span:first-child:before {
  background: #f4d34f;
}
.top .formBlock-steps span:first-child.active1:before {
  background: #f4d34f url("../i/okArr2.svg") center center no-repeat;
}
.top .formBlock-steps span:last-child:before {
  background: url("../i/okArr1.svg") center center no-repeat;
}
.top .formBlock-steps span:last-child.active:before {
  background: #f4d34f;
}
@media screen and (min-width: 768px) {
  .top .formBlock-steps {
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-steps {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-steps {
    margin-bottom: 40px;
  }
}
.top .formBlock-body {
  color: #1d1d1d;
}
.top .formBlock-title {
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-title {
    font-size: 2.7rem;
    margin-bottom: 34px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-title {
    font-size: 3.3rem;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-title {
    margin-bottom: 45px;
  }
}
.top .formBlock-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.1rem;
  line-height: 1.4rem;
}
@media screen and (min-width: 768px) {
  .top .formBlock-items {
    font-size: 1.5rem;
    line-height: 2.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-items {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-items {
    font-size: 1.6rem;
  }
}
.top .formBlock-item {
  width: calc(50% - 10px);
  text-align: center;
  background: #fff;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.05);
  border: 1.5px solid transparent;
  border-radius: 10px;
  margin: 10px 0;
  padding: 14px 10px;
}
.top .formBlock-item input {
  display: none;
}
.top .formBlock-item *:last-child span {
  display: block;
}
@media screen and (min-width: 768px) {
  .top .formBlock-item *:last-child span {
    display: inline;
  }
}
.top .formBlock-item img {
  height: 70px;
  margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-item img {
    height: 126px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-item img {
    height: 90px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-item img {
    height: 127px;
  }
}
.top .formBlock-item.selected {
  border: 2px solid #f4d34f;
}
@media screen and (min-width: 768px) {
  .top .formBlock-item {
    margin: 20px 0;
    border-radius: 20px;
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-item {
    width: calc(25% - 10px);
    margin: 18px 0;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-item {
    width: calc(25% - 30px);
    margin-bottom: 0;
  }
}
.top .formBlock-when {
  border-top: 2px solid #d9d9d9;
  padding-top: 18px;
  font-size: 1.4rem;
  margin-top: 25px;
}
.top .formBlock-when_type {
  margin-bottom: 14px;
}
@media screen and (min-width: 1024px) {
  .top .formBlock-when_type {
    margin-bottom: 0;
  }
}
.top .formBlock-when label {
  display: block;
  padding-bottom: 5px;
  font-family: 'RF Dewi Expanded Sbold';
}
.top .formBlock-when label span {
  color: #fbcb21;
}
@media screen and (min-width: 1024px) {
  .top .formBlock-when label {
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-when label {
    padding-bottom: 10px;
  }
}
.top .formBlock-when input,
.top .formBlock-when select {
  font-size: 1.4rem;
  margin-bottom: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .top .formBlock-when input,
  .top .formBlock-when select {
    font-size: 1.6rem;
    min-height: 75px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-when > div {
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-when > div {
    width: calc(50% - 5px);
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-when > div {
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-when {
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-when {
    font-size: 1.8rem;
    margin-top: 40px;
    padding-top: 25px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-when {
    font-size: 2rem;
    margin-top: 50px;
    padding-top: 40px;
  }
}
.top .formBlock-panel {
  width: 100%;
  background: #ebebeb;
  padding: 19px 15px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: none;
  position: relative;
}
.top .formBlock-panel_textarea label {
  display: block;
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding-bottom: 10px;
  font-family: 'RF Dewi Expanded Sbold';
  max-width: 200px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_textarea label {
    font-size: 1.5rem;
    padding-bottom: 15px;
    max-width: inherit;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_textarea label {
    font-size: 1.8rem;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_textarea label {
    font-size: 2rem;
  }
}
.top .formBlock-panel_textarea .textarea {
  min-height: 40px;
  height: auto;
  background: #fff;
  padding: 20px;
  color: #1d1d1d;
  font-size: 1.3rem;
  margin-bottom: 0;
  transition: all ease 500ms;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_textarea .textarea {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}
.top .formBlock-panel_textarea.d-size label {
  font-size: 1.5rem;
  max-width: inherit;
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_textarea.d-size label {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_textarea.d-size label {
    font-size: 2rem;
  }
}
.top .formBlock-panel_textarea.d-size .textarea {
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_textarea.d-size .textarea {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_items {
    display: flex;
    justify-content: space-between;
  }
}
.top .formBlock-panel_item {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 24px;
}
.top .formBlock-panel_item-top {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
}
.top .formBlock-panel_item-top > div {
  width: 50%;
  padding-left: 10px;
}
.top .formBlock-panel_item-top > div:first-child {
  text-align: center;
  padding-right: 10px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-top > div:first-child img {
    width: 84px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-top > div:first-child img {
    width: 88px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-top > div:first-child {
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-top > div:first-child {
    height: 90px;
    width: 45%;
    padding-right: 20px;
    padding-left: 0;
  }
}
.top .formBlock-panel_item-top > div > div {
  font-family: 'RF Dewi Expanded Sbold';
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-top > div {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-top > div {
    width: 55%;
    padding-left: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item-top > div {
    padding-left: 25px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-top {
    display: block;
    font-size: 1.5rem;
    line-height: 2.4rem;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-top {
    display: flex;
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding-top: 16px;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item-top {
    font-size: 1.6rem;
  }
}
.top .formBlock-panel_item-qt {
  padding-top: 16px;
}
.top .formBlock-panel_item-qt_title {
  font-family: 'RF Dewi Expanded Sbold';
  padding-bottom: 5px;
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-qt_title {
    font-size: 2rem;
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-qt_title {
    text-align: left;
    padding-bottom: 10px;
  }
}
.top .formBlock-panel_item-qt_nums {
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  justify-content: space-between;
}
.top .formBlock-panel_item-qt_nums span {
  display: block;
  color: #9e9e9e;
  font-size: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  background: url("../i/minus.svg") center center no-repeat;
  background-size: 12px auto;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-qt_nums span {
    background-size: 15px auto;
    width: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item-qt_nums span {
    background-size: 20px auto;
    width: 50px;
  }
}
.top .formBlock-panel_item-qt_nums *:last-child {
  background-image: url("../i/plus.svg");
}
.top .formBlock-panel_item-qt_nums input {
  border: 0;
  text-align: center;
  width: 100%;
  font-size: 1.1rem;
  padding: 10px 0;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item-qt_nums input {
    font-size: 1.6rem;
    padding: 12px 0;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-qt_nums input {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item-qt_nums input {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item-qt_nums {
    border-radius: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item-qt {
    padding-bottom: 6px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item-qt {
    padding-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel_item {
    width: 200px;
    width: calc(100% / 3 - 5px);
    border-radius: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel_item {
    width: calc(100% / 3 - 4px);
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel_item {
    width: calc(100% / 3 - 6px);
    padding: 15px 40px 25px;
    margin-bottom: 32px;
  }
}
.top .formBlock-panel:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ebebeb;
  top: -10px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel:after {
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid #ebebeb;
    top: -18px;
  }
}
.top .formBlock-panel[data-panel="0"]:after {
  left: calc(25% - 14px);
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel[data-panel="0"]:after {
    left: calc(25% - 24px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel[data-panel="0"]:after {
    left: 85px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel[data-panel="0"]:after {
    left: 115px;
  }
}
.top .formBlock-panel[data-panel="1"]:after {
  left: auto;
  right: calc(25% - 14px);
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel[data-panel="1"]:after {
    right: calc(25% - 24px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel[data-panel="1"]:after {
    left: 300px;
    right: auto;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel[data-panel="1"]:after {
    left: 410px;
  }
}
.top .formBlock-panel[data-panel="2"]:after {
  left: calc(25% - 14px);
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel[data-panel="2"]:after {
    left: calc(25% - 24px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel[data-panel="2"]:after {
    left: 520px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel[data-panel="2"]:after {
    left: 710px;
  }
}
.top .formBlock-panel[data-panel="3"]:after {
  left: auto;
  right: calc(25% - 14px);
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel[data-panel="3"]:after {
    right: calc(75% - 24px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel[data-panel="3"]:after {
    left: 740px;
    right: auto;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel[data-panel="3"]:after {
    left: 1010px;
  }
}
.top .formBlock-panel.boxes {
  padding: 15px;
  margin-top: 10px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel.boxes {
    margin-top: 24px;
    padding: 18px 16px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel.boxes {
    margin-top: 22px;
    padding: 23px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel.boxes {
    margin-top: 42px;
    padding: 30px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-panel {
    border-radius: 20px;
    margin-top: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-panel {
    margin-bottom: 0px;
    border-radius: 10px;
    padding: 20px 25px 25px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-panel {
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;
  }
}
.top .formBlock-btn {
  text-align: right;
  padding-top: 50px;
}
.top .formBlock-btn .d-btn {
  width: auto;
  min-width: 140px;
}
@media screen and (min-width: 768px) {
  .top .formBlock-btn .d-btn {
    min-width: 190px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-btn .d-btn {
    min-width: 260px;
  }
}
.top .formBlock-btn.twoBtn {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 35px;
}
.top .formBlock-btn.twoBtn .d-btn {
  font-size: 1.4rem;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 768px) {
  .top .formBlock-btn.twoBtn .d-btn.backBtn {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-btn.twoBtn .d-btn {
    font-size: 2rem;
    min-width: 260px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-btn.twoBtn {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-btn.twoBtn {
    padding-top: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-btn {
    padding-top: 90px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-btn {
    padding-top: 70px;
  }
}
.top .formBlock-last_item label {
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 1.4rem;
  padding-bottom: 5px;
  display: block;
}
.top .formBlock-last_item label span {
  color: #fbcb21;
}
@media screen and (min-width: 768px) {
  .top .formBlock-last_item label {
    font-size: 2rem;
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-last_item label {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-last_item label {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock-last_item input {
    margin-bottom: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-last_item input {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-last_item {
    width: calc(50% - 5px);
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock-last_item {
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock-last {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 321px) {
  .top .formBlock {
    padding: 20px 20px 50px;
  }
}
@media screen and (min-width: 414px) {
  .top .formBlock {
    padding: 20px 20px 50px;
  }
}
@media screen and (min-width: 768px) {
  .top .formBlock {
    padding: 30px 40px 70px;
    border-radius: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .top .formBlock {
    border-radius: 40px;
    padding-top: 40px;
    padding-bottom: 90px;
  }
}
@media screen and (min-width: 1280px) {
  .top .formBlock {
    border-radius: 50px;
    padding-bottom: 70px;
  }
}
@media screen and (min-width: 768px) {
  .top {
    padding: 50px 20px 60px;
    background: #0f2b70 url("../i/topBg.svg") calc(50% + 200px) 80px no-repeat;
    background-size: auto 300px;
    border-radius: 35px;
  }
  .top.formPage {
    background: #0f2b70 !important;
  }
}
@media screen and (min-width: 1024px) {
  .top {
    background-size: auto 420px;
    background-position: calc(50% + 280px) 70px;
    padding: 58px 25px 60px;
  }
}
@media screen and (min-width: 1280px) {
  .top {
    padding: 65px 25px 60px;
    background-position: calc(50% + 380px) 75px;
    border-radius: 25px;
  }
}
/*- six -*/
.six {
  padding-top: 50px;
  text-align: center;
}
.six .inner {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 10px;
}
@media screen and (min-width: 768px) {
  .six .inner {
    padding: 0 15px;
  }
}
.six-items {
  padding-top: 30px;
}
@media screen and (min-width: 768px) {
  .six-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 50px;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .six-items {
    max-width: 740px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1280px) {
  .six-items {
    max-width: 1100px;
  }
}
.six-item {
  font-size: 1.3rem;
  margin-top: 20px;
}
.six-item > * {
  display: block;
}
.six-item > span img {
  width: auto;
  height: 220px;
}
.six-item > div {
  color: #0f2b70;
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 1.5rem;
  line-height: 1.2;
}
@media screen and (min-width: 768px) {
  .six-item > div {
    font-size: 2.4rem;
    margin-top: 0px;
  }
}
.six-item > p {
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .six-item {
    width: 50%;
    margin-top: 50px;
    padding: 0 10px;
  }
}
@media screen and (min-width: 1024px) {
  .six-item {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1280px) {
  .six-item {
    width: 30%;
    font-size: 1.5rem;
  }
}
.six-btn {
  margin-top: 40px;
  font-size: 1.5rem;
}
.six-btn br {
  display: none;
}
@media screen and (min-width: 768px) {
  .six-btn {
    margin-top: 100px;
  }
  .six-btn br {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .six {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
/*- removalRequest -*/
.removalRequest {
  padding-top: 100px;
}
.removalRequest-img {
  width: 100%;
  position: relative;
  max-width: 1260px;
  margin: 0 auto;
}
.removalRequest-img:before {
  content: '';
  display: block;
  border-radius: 35px;
  width: 100%;
  height: 0;
  padding-bottom: 58.82352941176471%;
  background: url("../i/requestBgM.png") center center no-repeat;
  background-size: contain;
  box-shadow: 0px 30px 70px -20px rgba(0,0,0,0.25);
}
@media screen and (min-width: 768px) {
  .removalRequest-img:before {
    background: url("../i/requestBg.jpg") center center no-repeat;
    padding-bottom: 0;
    height: 500px;
  }
}
.removalRequest-img > div {
  position: absolute;
  padding: 20px 15px;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .removalRequest-img > div {
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .removalRequest-img > div {
    padding: 20px 0;
    left: 50%;
    max-width: 960px;
    margin-left: -480px;
  }
}
.removalRequest-title {
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 1.6rem;
  line-height: 1.2;
  max-width: 200px;
  padding-top: 20px;
}
@media screen and (min-width: 768px) {
  .removalRequest-title {
    font-size: 3.3rem;
    line-height: normal;
    max-width: 400px;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .removalRequest-title {
    font-size: 4rem;
    max-width: 460px;
  }
}
.removalRequest-btn {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .removalRequest-btn {
    text-align: left;
  }
}
.removalRequest-items {
  padding: 50px 10px 0;
  font-weight: 400;
  font-size: 1.3rem;
}
@media screen and (min-width: 768px) {
  .removalRequest-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.6rem;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 60px;
    max-width: 960px;
    margin: 0 auto;
    padding: 50px 15px 0;
  }
}
@media screen and (min-width: 1024px) {
  .removalRequest-items {
    padding: 50px 0;
  }
}
.removalRequest-item {
  padding: 20px 0px;
}
.removalRequest-item > div {
  font-size: 1.6rem;
  font-family: 'RF Dewi Expanded Sbold';
}
@media screen and (min-width: 768px) {
  .removalRequest-item > div {
    font-size: 2rem;
    line-height: normal;
  }
}
.removalRequest-item p {
  margin-top: 15px;
}
.removalRequest-item:last-child {
  border-bottom: 1px solid #c4c4c4;
}
@media screen and (min-width: 768px) {
  .removalRequest-item:last-child {
    border-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .removalRequest-item {
    width: calc(50% - 30px);
  }
}
@media screen and (min-width: 1024px) {
  .removalRequest-item {
    width: 460px;
  }
}
@media screen and (min-width: 768px) {
  .removalRequest {
    padding-top: 0px;
  }
}
/*- serts -*/
.serts {
  padding: 50px 10px;
  font-size: 1.3rem;
}
@media screen and (min-width: 768px) {
  .serts .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;
  }
}
.serts-img {
  width: 100%;
  max-width: 410px;
  margin: 0 auto 40px;
}
.serts-img_wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 84%;
}
.serts-img_wrapper > div {
  position: absolute;
  width: 80%;
  height: auto;
  box-shadow: 0px 15px 35px rgba(0,0,0,0.25);
}
.serts-img_first {
  left: 0;
  top: 0;
  z-index: 2;
}
.serts-img_second {
  right: 0;
  bottom: 0;
  z-index: 1;
}
.serts-img img {
  width: 100%;
  height: auto;
  vertical-align: top;
}
@media screen and (min-width: 768px) {
  .serts-img {
    width: calc(50% - 30px);
    margin: 0;
  }
}
.serts-text {
  font-weight: 400;
}
.serts-text > div {
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: 'RF Dewi Expanded Sbold';
}
@media screen and (min-width: 768px) {
  .serts-text > div {
    font-size: 2rem;
    line-height: normal;
  }
}
@media screen and (min-width: 768px) {
  .serts-text {
    width: calc(50% - 30px);
  }
}
@media screen and (min-width: 1024px) {
  .serts-text {
    width: 460px;
  }
}
@media screen and (min-width: 768px) {
  .serts {
    padding: 60px 15px 120px;
    font-size: 1.7rem;
  }
}
/*- -*/
.bottomForm {
  border-radius: 15px;
  color: #fff;
  padding: 50px 10px 80px;
  background: #0f2b70;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .bottomForm .inner {
    max-width: 600px;
    margin: 0 auto;
  }
}
.bottomForm-title {
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 2.5rem;
  line-height: normal;
}
.bottomForm-title + p {
  display: block;
  font-family: 'RF Dewi Expanded Sbold';
  font-size: 1.6rem;
  line-height: 1;
}
@media screen and (min-width: 768px) {
  .bottomForm-title + p {
    font-size: 2.4rem;
    padding: 0 60px;
  }
}
@media screen and (min-width: 768px) {
  .bottomForm-title {
    font-size: 4rem;
  }
}
.bottomForm form {
  padding-top: 20px;
  max-width: 420px;
  margin: 0 auto;
}
.bottomForm form:after {
  content: '';
  display: inline-block;
  background: url("../i/logoOnly.svg") center center no-repeat;
  background-size: auto 100%;
  min-height: 64px;
  font-size: 0;
  width: 112px;
  margin-top: 70px;
}
.bottomForm form .d-btn {
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .bottomForm form {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .bottomForm {
    padding: 100px 25px 100px;
    background: #0f2b70;
    border-radius: 35px;
  }
}
.fake-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 2;
}
