@font-face {
  font-family: 'RF Dewi Expanded';
  src: url('../fonts/RFDewiExpanded-Regular.eot');
  src: local('RF Dewi Expanded Regular'), local('RFDewiExpanded-Regular'),
          url('../fonts/RFDewiExpanded-Regular.eot?#iefix') format('embedded-opentype'),
          url('../fonts/RFDewiExpanded-Regular.woff2') format('woff2'),
          url('../fonts/RFDewiExpanded-Regular.woff') format('woff'),
          url('../fonts/RFDewiExpanded-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RF Dewi Expanded Sbold';
  src: url('../fonts/RFDewiExpanded-Semibold.eot');
  src: local('RF Dewi Expanded Semibold'), local('RFDewiExpanded-Semibold'),
          url('../fonts/RFDewiExpanded-Semibold.eot?#iefix') format('embedded-opentype'),
          url('../fonts/RFDewiExpanded-Semibold.woff2') format('woff2'),
          url('../fonts/RFDewiExpanded-Semibold.woff') format('woff'),
          url('../fonts/RFDewiExpanded-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
