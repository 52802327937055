@require "vars.styl";

/*  <buttons>  */
input
button
	outline 0
.btn
	display block
	color $colorText
	font-size 2rem
	font-weight bold
	border 0
	padding 10px 20px
	text-transform uppercase
	box-shadow 0px 5px 0 #a77d0b
	margin-bottom 3px
	cursor pointer
	outline 0
	transition all .2s
	background: #e5ac11;
	background: -moz-linear-gradient(top,  #e5ac11 0%, #fdda4d 100%)
	background: -webkit-linear-gradient(top,  #e5ac11 0%,#fdda4d 100%)
	background: linear-gradient(to bottom,  #e5ac11 0%,#fdda4d 100%)
	&:hover
		text-decoration none
		background-color darken($colorWhite, 5)
	&:active
		background-color darken($colorWhite, 5)
		box-shadow 0 5px 0 transparent
		transform translateY(3px)
	@media $win414
		display inline-block
		vertical-align top
		padding 15px 20px
/*  </buttons>  */

/*  <inputs>  */
.input-text
.d-input
.textarea
.ui-selectmenu-button
	width 100%
	display block
	border 1px solid #fff
	color #fff
	padding 23px 20px
	border-radius 10px
	margin-bottom 15px
	background none
	outline 0
	&.bordered
	&.ui-widget {
		width 100%
		border: 2px solid #D9D9D9;
		box-sizing: border-box;
		border-radius: 10px;
		background #fff
		color #1D1D1D
		padding 23px 20px
		.ui-selectmenu-icon {
			//display none
			content ''
			display block
			background url("../i/union.svg") center center no-repeat
			width 17px
			height 10px
			position: absolute;
			right 6%
			top 50%
			margin-top -5px
			transition all ease 200ms
		}
		&:hover {
			background-color inherit
			.ui-selectmenu-icon {

			}
		}
		&.ui-selectmenu-button-open {
			border-color #F4D34F
			background-color #fff
			z-index 102 !important
			.ui-selectmenu-icon {
				transform rotate(180deg)
			}
		}
		@media $win768 {
			border-radius: 15px;
			line-height 2.6rem
			min-height 75px
			margin-bottom 0
		}
	}
	&.white {
		background #fff
		color #1D1D1D
	}
	&::placeholder
		color #D9D9D9
	&--icon
		padding-left 52px
	&.error {
		color #FF6161
		border 2px solid #FF6161
	}
	@media $win768 {
		font-size 1.6rem
		padding 25px 30px
		margin-bottom 30px
		width 100%
		border-radius 10px
	}
	@media $win1024 {
		padding 20px 20px
		border-radius 15px
	}
	@media $win1280 {
		padding 20px 30px
		border-radius 15px
		min-height 70px
	}
input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

.ui-selectmenu-menu {
	ul {
		border: 2px solid #F4D34F !important;
		box-sizing: border-box;
		border-radius: 10px;
		padding-top 20px
		margin-top -28px
		.ui-menu-item-wrapper {
			padding 15px 15px
			font-family: 'RF Dewi Expanded Sbold';
			border 0
			&.ui-state-active {
				background #F4D34F
			}
		}
	}
}

.textarea
	text-align left
	height 160px
	min-height 160px
	padding 10px 15px
	resize none
	transition box-shadow .2s
	&::placeholder
		color #D9D9D9
.input-file
	position absolute
	left -9999px
	& + label
		display inline-flex
		align-items center
		height 52px
		color $colorWhite
		font-weight 300
		font-size 2.1rem
		background-color $colorOrange
		border-radius (@height / 2)
		padding 0 30px
		box-shadow 0 5px 0 #bb661a
		cursor pointer
		transition all .2s
		-webkit-user-select none
		user-select none
		&:hover
			background-color darken($colorOrange, 5)
		&:active
			box-shadow 0 5px 0 transparent
			transform translateY(3px)
.select
	width 100%
input
	&.input_error
		border 2px solid red
label
	&.input_error
		color red
/*  </inputs>  */

/*  <layout>  */
.form
	&-pers
		a
			white-space nowrap
			color: #7fa6ac
			text-decoration underline
			&:hover
				text-decoration none
	&-row
		position relative
		margin-bottom 18px
		&:first-child
			margin-top 0
		&:last-child
			margin-bottom 0

.has-error
	position relative
	.form-error
		position absolute
		left 0
		top calc(100%)
		font-size 1.2rem
		color $colorRed
		width 100%
		background rgba(255,255,255,0.6)
		text-align left
		padding 1px 4px 3px
.page-title
	position relative
	font-weight 500
	font-size 3.6rem
	line-height 4.8rem
	text-transform uppercase
	text-align center
	padding-top 40px
	@media $win640
		text-align left
	&::before
		position absolute
		top 0
		left 50%
		width 218px
		height 22px
		content ''
		transform translateX(-50%)
		@media $win640
			left 0
			transform none
	&--blue
		&::before
			background-color $colorBlue
	&--green
		&::before
			background-color $colorGreen
	&--orange
		&::before
			background-color $colorOrange
.color
	&-blue
		color $colorBlue
	&-green
		color $colorGreen
	&-orange
		color $colorOrange
	&-gray
		color $colorGray
	&-red
		color $colorRed
.no-scroll
	overflow hidden
.fadeIn
	animation fadeIn .5s
/*  </layout>  */

.mb-0 {
	margin-bottom 0 !important
}

/*  <animation>  */
@keyframes fadeIn
	from
		opacity 0
	to
		opacity 1
/*  </animation>  */

.dot-list
	text-align left
	padding 0
	li
		list-style none
		position relative
		margin 10px 0
		padding-left 30px
		&:after
			content ''
			display block
			width 17px
			height 17px
			border 4px solid $colorWhite
			background $colorOrange
			position absolute
			left 0
			top 3px
.icon-list
	text-align left
	padding 0
	li
		list-style none
		position relative
		margin 15px 0
		padding-left 36px
		&:after
			content ''
			display block
			width 21px
			height 20px
			position absolute
			left 0
			top 3px
			background url("../i/li.png") center center no-repeat
.icon-list.green
	li
		&:after
			width 23px
			height 27px
			background-image url("../i/li1.png")
.icon-list.white
	li
		&:after
			width 21px
			height 23px
			top 0px
			background-image url("../i/li2.png")

.clearfix
	&:after
		content ''
		display block
		clear both

.fancybox-close-small
	padding 0
	svg
		width 44px
		//stroke red !important

.h1 {
	font-size 1.3rem
	line-height 1.4
	font-family: 'RF Dewi Expanded Sbold';
	@media $win414 {
		font-size 1.8rem
	}
	@media $win768 {
		font-size 2.4rem
		line-height 1.7
	}
	@media $win1024 {
		font-size 3.4rem
		line-height 1.7
	}
}
.h2 {
	font-size 1.5rem
	line-height 1.4
	font-family: 'RF Dewi Expanded Sbold';
	@media $win768 {
		font-size 2.3rem
		line-height 1.2
	}
}
.h3 {
	font-size 1.8rem
	line-height 1.4
	font-family: 'RF Dewi Expanded Sbold';
	@media $win768 {
		font-size 4rem
	}
}

.d-btn {
	display inline-block
	font-weight bold
	vertical-align top
	background: linear-gradient(274.03deg, #F87700 -23.51%, #F9D423 97.88%), #E4C65C;
	border-radius: 15px;
	border 0
	max-width 360px
	width 100%
	cursor pointer
	padding 19px 25px
	font-size 1.5rem
	line-height 1.4
	color #000
	text-align center
	transition all ease 400ms
	&:hover {
		background: linear-gradient(274.03deg, #FACF21 -23.51%, #FACF21 97.88%), #FACF21;
		//box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.35);
		text-decoration none
		//transition all ease 400ms
	}
	&:active {
		background: #E5BE1D;
		//transition all ease 400ms
	}
	&.backBtn {
		background #F1F1F1 !important
		padding 19px 5px
		@media $win768 {
			padding: 25px 35px;
		}
	}
	@media $win768 {
		font-size 2rem
		padding 25px 35px
	}
	@media $win1024 {
		padding 22px 35px
	}
}

.fancybox {
	&-content {
		border-radius 15px
		min-width 308px
	}
}
.fakeSelect1 {
	position: relative;
	select {
		position: relative;
	}
	&:before {
		content ''
		display block
		background url("../i/union.svg") center center no-repeat
		width 17px
		height 10px
		position: absolute;
		right 6%
		top 50%
		margin-top -5px
	}
}
label {
	&.error {
		color red
		font-size 1.2rem
		display none !important
	}
}
