/*  <buttons>  */
input,
button {
  outline: 0;
}
.btn {
  display: block;
  color: #272727;
  font-size: 2rem;
  font-weight: bold;
  border: 0;
  padding: 10px 20px;
  text-transform: uppercase;
  box-shadow: 0px 5px 0 #a77d0b;
  margin-bottom: 3px;
  cursor: pointer;
  outline: 0;
  transition: all 0.2s;
  background: #e5ac11;
  background: -moz-linear-gradient(top, #e5ac11 0%, #fdda4d 100%);
  background: -webkit-linear-gradient(top, #e5ac11 0%, #fdda4d 100%);
  background: linear-gradient(to bottom, #e5ac11 0%, #fdda4d 100%);
}
.btn:hover {
  text-decoration: none;
  background-color: #f2f2f2;
}
.btn:active {
  background-color: #f2f2f2;
  box-shadow: 0 5px 0 transparent;
  transform: translateY(3px);
}
@media screen and (min-width: 414px) {
  .btn {
    display: inline-block;
    vertical-align: top;
    padding: 15px 20px;
  }
}
/*  </buttons>  */
/*  <inputs>  */
.input-text,
.d-input,
.textarea,
.ui-selectmenu-button {
  width: 100%;
  display: block;
  border: 1px solid #fff;
  color: #fff;
  padding: 23px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  background: none;
  outline: 0;
}
.input-text.bordered,
.d-input.bordered,
.textarea.bordered,
.ui-selectmenu-button.bordered,
.input-text.ui-widget,
.d-input.ui-widget,
.textarea.ui-widget,
.ui-selectmenu-button.ui-widget {
  width: 100%;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
  color: #1d1d1d;
  padding: 23px 20px;
}
.input-text.bordered .ui-selectmenu-icon,
.d-input.bordered .ui-selectmenu-icon,
.textarea.bordered .ui-selectmenu-icon,
.ui-selectmenu-button.bordered .ui-selectmenu-icon,
.input-text.ui-widget .ui-selectmenu-icon,
.d-input.ui-widget .ui-selectmenu-icon,
.textarea.ui-widget .ui-selectmenu-icon,
.ui-selectmenu-button.ui-widget .ui-selectmenu-icon {
  content: '';
  display: block;
  background: url("../i/union.svg") center center no-repeat;
  width: 17px;
  height: 10px;
  position: absolute;
  right: 6%;
  top: 50%;
  margin-top: -5px;
  transition: all ease 200ms;
}
.input-text.bordered:hover,
.d-input.bordered:hover,
.textarea.bordered:hover,
.ui-selectmenu-button.bordered:hover,
.input-text.ui-widget:hover,
.d-input.ui-widget:hover,
.textarea.ui-widget:hover,
.ui-selectmenu-button.ui-widget:hover {
  background-color: inherit;
}
.input-text.bordered.ui-selectmenu-button-open,
.d-input.bordered.ui-selectmenu-button-open,
.textarea.bordered.ui-selectmenu-button-open,
.ui-selectmenu-button.bordered.ui-selectmenu-button-open,
.input-text.ui-widget.ui-selectmenu-button-open,
.d-input.ui-widget.ui-selectmenu-button-open,
.textarea.ui-widget.ui-selectmenu-button-open,
.ui-selectmenu-button.ui-widget.ui-selectmenu-button-open {
  border-color: #f4d34f;
  background-color: #fff;
  z-index: 102 !important;
}
.input-text.bordered.ui-selectmenu-button-open .ui-selectmenu-icon,
.d-input.bordered.ui-selectmenu-button-open .ui-selectmenu-icon,
.textarea.bordered.ui-selectmenu-button-open .ui-selectmenu-icon,
.ui-selectmenu-button.bordered.ui-selectmenu-button-open .ui-selectmenu-icon,
.input-text.ui-widget.ui-selectmenu-button-open .ui-selectmenu-icon,
.d-input.ui-widget.ui-selectmenu-button-open .ui-selectmenu-icon,
.textarea.ui-widget.ui-selectmenu-button-open .ui-selectmenu-icon,
.ui-selectmenu-button.ui-widget.ui-selectmenu-button-open .ui-selectmenu-icon {
  transform: rotate(180deg);
}
@media screen and (min-width: 768px) {
  .input-text.bordered,
  .d-input.bordered,
  .textarea.bordered,
  .ui-selectmenu-button.bordered,
  .input-text.ui-widget,
  .d-input.ui-widget,
  .textarea.ui-widget,
  .ui-selectmenu-button.ui-widget {
    border-radius: 15px;
    line-height: 2.6rem;
    min-height: 75px;
    margin-bottom: 0;
  }
}
.input-text.white,
.d-input.white,
.textarea.white,
.ui-selectmenu-button.white {
  background: #fff;
  color: #1d1d1d;
}
.input-text::placeholder,
.d-input::placeholder,
.textarea::placeholder,
.ui-selectmenu-button::placeholder {
  color: #d9d9d9;
}
.input-text--icon,
.d-input--icon,
.textarea--icon,
.ui-selectmenu-button--icon {
  padding-left: 52px;
}
.input-text.error,
.d-input.error,
.textarea.error,
.ui-selectmenu-button.error {
  color: #ff6161;
  border: 2px solid #ff6161;
}
@media screen and (min-width: 768px) {
  .input-text,
  .d-input,
  .textarea,
  .ui-selectmenu-button {
    font-size: 1.6rem;
    padding: 25px 30px;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .input-text,
  .d-input,
  .textarea,
  .ui-selectmenu-button {
    padding: 20px 20px;
    border-radius: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .input-text,
  .d-input,
  .textarea,
  .ui-selectmenu-button {
    padding: 20px 30px;
    border-radius: 15px;
    min-height: 70px;
  }
}
input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}
input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.ui-selectmenu-menu ul {
  border: 2px solid #f4d34f !important;
  box-sizing: border-box;
  border-radius: 10px;
  padding-top: 20px;
  margin-top: -28px;
}
.ui-selectmenu-menu ul .ui-menu-item-wrapper {
  padding: 15px 15px;
  font-family: 'RF Dewi Expanded Sbold';
  border: 0;
}
.ui-selectmenu-menu ul .ui-menu-item-wrapper.ui-state-active {
  background: #f4d34f;
}
.textarea {
  text-align: left;
  height: 160px;
  min-height: 160px;
  padding: 10px 15px;
  resize: none;
  transition: box-shadow 0.2s;
}
.textarea::placeholder {
  color: #d9d9d9;
}
.input-file {
  position: absolute;
  left: -9999px;
}
.input-file + label {
  display: inline-flex;
  align-items: center;
  height: 52px;
  color: #fff;
  font-weight: 300;
  font-size: 2.1rem;
  background-color: #ec7027;
  border-radius: 26px;
  padding: 0 30px;
  box-shadow: 0 5px 0 #bb661a;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  user-select: none;
}
.input-file + label:hover {
  background-color: #e56214;
}
.input-file + label:active {
  box-shadow: 0 5px 0 transparent;
  transform: translateY(3px);
}
.select {
  width: 100%;
}
input.input_error {
  border: 2px solid #f00;
}
label.input_error {
  color: #f00;
}
/*  </inputs>  */
/*  <layout>  */
.form-pers a {
  white-space: nowrap;
  color: #7fa6ac;
  text-decoration: underline;
}
.form-pers a:hover {
  text-decoration: none;
}
.form-row {
  position: relative;
  margin-bottom: 18px;
}
.form-row:first-child {
  margin-top: 0;
}
.form-row:last-child {
  margin-bottom: 0;
}
.has-error {
  position: relative;
}
.has-error .form-error {
  position: absolute;
  left: 0;
  top: calc(100%);
  font-size: 1.2rem;
  color: #a31811;
  width: 100%;
  background: rgba(255,255,255,0.6);
  text-align: left;
  padding: 1px 4px 3px;
}
.page-title {
  position: relative;
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 4.8rem;
  text-transform: uppercase;
  text-align: center;
  padding-top: 40px;
}
@media screen and (min-width: 640px) {
  .page-title {
    text-align: left;
  }
}
.page-title::before {
  position: absolute;
  top: 0;
  left: 50%;
  width: 218px;
  height: 22px;
  content: '';
  transform: translateX(-50%);
}
@media screen and (min-width: 640px) {
  .page-title::before {
    left: 0;
    transform: none;
  }
}
.page-title--blue::before {
  background-color: #2bbde7;
}
.page-title--green::before {
  background-color: #56ca69;
}
.page-title--orange::before {
  background-color: #ec7027;
}
.color-blue {
  color: #2bbde7;
}
.color-green {
  color: #56ca69;
}
.color-orange {
  color: #ec7027;
}
.color-gray {
  color: #a3a3a2;
}
.color-red {
  color: #a31811;
}
.no-scroll {
  overflow: hidden;
}
.fadeIn {
  animation: fadeIn 0.5s;
}
/*  </layout>  */
.mb-0 {
  margin-bottom: 0 !important;
}
/*  <animation>  */
/*  </animation>  */
.dot-list {
  text-align: left;
  padding: 0;
}
.dot-list li {
  list-style: none;
  position: relative;
  margin: 10px 0;
  padding-left: 30px;
}
.dot-list li:after {
  content: '';
  display: block;
  width: 17px;
  height: 17px;
  border: 4px solid #fff;
  background: #ec7027;
  position: absolute;
  left: 0;
  top: 3px;
}
.icon-list {
  text-align: left;
  padding: 0;
}
.icon-list li {
  list-style: none;
  position: relative;
  margin: 15px 0;
  padding-left: 36px;
}
.icon-list li:after {
  content: '';
  display: block;
  width: 21px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 3px;
  background: url("../i/li.png") center center no-repeat;
}
.icon-list.green li:after {
  width: 23px;
  height: 27px;
  background-image: url("../i/li1.png");
}
.icon-list.white li:after {
  width: 21px;
  height: 23px;
  top: 0px;
  background-image: url("../i/li2.png");
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}
.fancybox-close-small {
  padding: 0;
}
.fancybox-close-small svg {
  width: 44px;
}
.h1 {
  font-size: 1.3rem;
  line-height: 1.4;
  font-family: 'RF Dewi Expanded Sbold';
}
@media screen and (min-width: 414px) {
  .h1 {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 768px) {
  .h1 {
    font-size: 2.4rem;
    line-height: 1.7;
  }
}
@media screen and (min-width: 1024px) {
  .h1 {
    font-size: 3.4rem;
    line-height: 1.7;
  }
}
.h2 {
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: 'RF Dewi Expanded Sbold';
}
@media screen and (min-width: 768px) {
  .h2 {
    font-size: 2.3rem;
    line-height: 1.2;
  }
}
.h3 {
  font-size: 1.8rem;
  line-height: 1.4;
  font-family: 'RF Dewi Expanded Sbold';
}
@media screen and (min-width: 768px) {
  .h3 {
    font-size: 4rem;
  }
}
.d-btn {
  display: inline-block;
  font-weight: bold;
  vertical-align: top;
  background: linear-gradient(274.03deg, #f87700 -23.51%, #f9d423 97.88%), #e4c65c;
  border-radius: 15px;
  border: 0;
  max-width: 360px;
  width: 100%;
  cursor: pointer;
  padding: 19px 25px;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #000;
  text-align: center;
  transition: all ease 400ms;
}
.d-btn:hover {
  background: linear-gradient(274.03deg, #facf21 -23.51%, #facf21 97.88%), #facf21;
  text-decoration: none;
}
.d-btn:active {
  background: #e5be1d;
}
.d-btn.backBtn {
  background: #f1f1f1 !important;
  padding: 19px 5px;
}
@media screen and (min-width: 768px) {
  .d-btn.backBtn {
    padding: 25px 35px;
  }
}
@media screen and (min-width: 768px) {
  .d-btn {
    font-size: 2rem;
    padding: 25px 35px;
  }
}
@media screen and (min-width: 1024px) {
  .d-btn {
    padding: 22px 35px;
  }
}
.fancybox-content {
  border-radius: 15px;
  min-width: 308px;
}
.fakeSelect1 {
  position: relative;
}
.fakeSelect1 select {
  position: relative;
}
.fakeSelect1:before {
  content: '';
  display: block;
  background: url("../i/union.svg") center center no-repeat;
  width: 17px;
  height: 10px;
  position: absolute;
  right: 6%;
  top: 50%;
  margin-top: -5px;
}
label.error {
  color: #f00;
  font-size: 1.2rem;
  display: none !important;
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
