@require "fonts.styl"
@require "vars.styl"

/*  <general>  */
html
	font-size 62.5%
	cursor default
	-webkit-text-size-adjust 100%
	-ms-text-size-adjust 100%
	-webkit-tap-highlight-color transparent
	text-rendering optimizeLegibility
	-webkit-font-smoothing antialiased
	font-smoothing antialiased
	-moz-osx-font-smoothing grayscale

body
	margin 0
	padding 0
	min-height 100vh
	color $colorText
	font 1.5rem/1.73 'Montserrat', sans-serif;
	font-weight 500
	background-color #fff
:-moz-placeholder
	opacity 1
	color currentColor
:-ms-input-placeholder
	opacity: 1
	color currentColor
a
	color $colorBlueMenu
	font inherit
	text-decoration none
	transition all .2s
	&:hover
		text-decoration underline
	&.lined
		text-decoration underline
		&:hover
			text-decoration none
img
	max-width 100%
.align-center
	text-align center
/*  </general>  */

	#HTMLBlock {
		background #fff
		padding 6px
		overflow: hidden;
		position relative
		/*&:after {
			content ''
			display block
			position: absolute;
			width 100%
			height 100%
			background url("../i/d/1.jpg") center top no-repeat
			opacity 0.5
			left 0
			top 0
		}*/
		&.formPage {
			padding-left 0
			padding-right 0
			min-height 100vh
			background #0F2B70
			.top {
				border-radius 0
				.inner {
					.header {
						margin-bottom 32px
						@media $win768 {
							margin-bottom 50px
						}
						@media $win1024 {
							margin-bottom 54px
						}
						@media $win1280 {
							margin-bottom 64px
						}
					}
				}
			}
		}
		&.thanksPage {
			padding-left 0
			padding-right 0
			min-height 100vh
			background #0F2B70
			&_text {
				padding-top 100px
				text-align center
				@media $win768 {
					text-align left
				}
			}
			.top {
				border-radius 0
				.inner {
					.header {
						margin-bottom 32px
						@media $win768 {
							margin-bottom 150px
						}
						@media $win1024 {
							margin-bottom 220px
						}
						@media $win1280 {
							margin-bottom 180px
						}
					}
					/*.h1 {
						text-align center
						@media $win768 {
							text-align left
						}
					}*/
					&:after {
						margin-top 30px
						content ''
						display block
						width 100%
						height 0
						padding-bottom 71.42857142857143%
						background url("../i/topBgM.svg") center center no-repeat
						background-size 100% auto
						@media $win768 {
							display none
						}
					}
				}
			}
		}
		@media $win768 {
			padding 15px
		}
	}
	.main {
		margin 0 auto
		max-width 1750px
		@media $win1024 {
			max-width 100%
		}
	}

	.top {
		border-radius 15px
		min-height 500px
		color #fff
		padding 30px 10px 50px
		background #0F2B70
		.inner {
			position: relative
			margin 0 auto
			max-width 420px
			.header {
				display flex
				flex-direction row
				justify-content space-between
				align-items center
				margin-bottom 30px
				padding 0 0px
				.logo {
					display block
					background url("../i/logo.svg") center center no-repeat
					background-size auto 100%;
					min-height 40px
					font-size 0
					width 130px
					@media $win768 {
						width 170px
						min-height 54px
					}
				}
				.phone {
					font-size 1.5rem
					font-weight 600
					span {
						color #8597C6
					}
					@media $win768 {
						font-size 2rem
					}
				}
				@media $win414 {
					//padding 0
				}
				@media $win768 {
					margin-bottom 180px
					//padding: 0
					//margin-bottom 50px
				}
				@media $win1024 {
					margin-bottom 140px
					//padding 0
				}
			}
			@media $win768 {
				max-width 860px
			}
			@media $win1280 {
				max-width 1160px
			}
		}
		&_body {
			&-h1 {
				//max-width 100%
				span {
					color $colorYellow
				}
				.customTopSelect {
					display inline-block
					margin-right 20px
					position: relative
					&:after {
						position: absolute;
						right -15px
						top 50%
						margin-top 0px
						content ''
						display inline-block
						width: 0;
						height: 0;
						border-left: 5px solid transparent;
						border-right: 5px solid transparent;
						border-top: 6px solid $colorYellow;
						@media $win768 {
							right -20px
						}
					}
					select {
						background none
						appearance: none;
						-webkit-appearance: none;
						-moz-appearance: none;
						border 0
						color $colorYellow
						padding 0 10px
						margin 0 -10px
						cursor: pointer;
						line-height inherit

						option {
							font-size 1.4rem
							color #000
							display block
							font-weight:normal;
						}

						&::-ms-expand { /* for IE 11 */
							display: none;
						}

						&:hover,
						&:focus {
							border 0
							outline: none;
						}
					}
					&_value {
						color $colorYellow
						text-decoration none
						border-bottom 1px solid @color
						cursor pointer
					}
					&:hover {
						.customTopSelect_value {
							border-bottom 1px solid transparent
						}
					}
					ul {
						position: absolute;
						left 0
						top 100%
						font-size 1.2rem
						background #fff
						z-index 3
						margin 0
						padding 10px 0
						border-radius 15px
						overflow hidden
						li {
							padding 5px 40px 5px 20px
							margin 0
							list-style none
							white-space nowrap
							color #000
							&:hover {
								background $colorYellow
								cursor: pointer;
							}
							&[data-selected] {
								background $colorYellow
							}
						}
						@media $win414 {
							font-size 1.4rem
						}
						@media $win768 {
							font-size 1.6rem
						}
					}
				}
				@media $win768 {
					//max-width 50%
					font-size 2.4rem
				}
				@media $win1024 {
					//max-width 55%
				}
				@media $win1280 {
					//max-width 55%
					font-size 3.4rem
				}
			}
			&-h2 {
				padding-top 40px
				@media $win768 {
					padding-top 60px
				}
			}
			&-form {
				padding-top 20px
				form {
					.customSelectWrapper {
						box-shadow: 0px 30px 40px -10px rgba(0, 0, 0, 0.25);
						.customSelect {
							width 100%
							background #fff
							padding 16px 20px
							color #000
							cursor: pointer;
							position relative

							/*&:after {
								content ''
								display block
								background url("../i/union.svg") center center no-repeat
								width 17px
								height 10px
								position: absolute;
								right 6%
								top 50%
								margin-top -5px
							}*/

							input {
								padding 0
								cursor: pointer;
								position relative
								color #000
								width 100%
								height 100%
								vertical-align top
								z-index: 1;
								border 0
								font-weight bold
								&::placeholder {
									color #000
								}
							}


							&.customSelectFrom {
								border-radius 15px 15px 0 0
								border-bottom 1px solid #C4C4C4
								@media $win768 {
									border-radius 15px 0 0 15px
									border-bottom 0
									border-right 1px solid #C4C4C4
								}
							}

							&.customSelectTo {
								border-radius 0 0 10px 10px
								@media $win768 {
									border-radius 0 15px 15px 0
								}
							}

							> div {

								label {
									opacity 0.5
									margin-right 6px
									@media $win1024 {
										display block
									}
									@media $win1280 {
										display inline-block
									}
								}

								span {
									display block
									position: relative;
									font-weight 600
									/*label {
										position: absolute;
										left 0
										z-index: 2;
										transition all ease 200ms
									}*/
									@media $win1280 {
										display inline-block
									}
								}
								@media $win1280 {
									display flex
								}
							}
							/*input:focus + label {
								font-size 50%
								margin-top -15px
							}*/
							@media $win768 {
								width 50%
								padding 36px 20px
								font-size 1.7rem
							}
							@media $win1024 {
								font-size 2rem
								line-height 1.4
								padding 22px 30px
							}
							@media $win1280 {
								display flex
								flex-direction column
								justify-content: center;
								padding 36px 30px
							}
						}
						@media $win768 {
							display flex
							flex-direction row
							justify-content space-between
						}
						@media $win1024 {
							width 75%
							padding-right 10px;
						}
					}
					&:after {
						margin-top 30px
						content ''
						display block
						width 100%
						height 0
						padding-bottom 71.42857142857143%
						background url("../i/topBgM.svg") center center no-repeat
						background-size 100% auto
						@media $win768 {
							display none
						}
					}

					@media $win1024 {
						display flex
						flex-direction row
						justify-content space-between
						width 100%
						align-items: center;
					}
				}
				&_btn {
					padding-top 10px
					text-align center
					box-shadow: 0px 30px 40px -10px rgba(0, 0, 0, 0.25);
					@media $win768 {
						padding-top 20px
					}
					@media $win1024 {
						padding-top 0px
					}
				}
				@media $win1024 {
					padding-top 40px
				}
			}
			&-h3 {
				padding-top 40px
				@media $win768 {
					padding-top 140px
				}
			}
			&-items {
				padding-top 30px
				@media $win768 {
					display flex
					flex-direction row
					justify-content space-between
					padding-top 40px
					flex-wrap wrap
				}
			}
			&-item {
				font-size 1.3rem
				margin-top 15px
				border-top 1px solid rgba(255,255,255,.1);
				padding-top 15px
				> div {
					font-family: 'RF Dewi Expanded Sbold';
					font-size 1.6rem
					line-height 1.2
					color $colorYellow
					position relative
					padding-right 30px
					&:after {
						content ''
						display block
						width: 0;
						height: 0;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-top: 7px solid $colorYellow;
						position absolute
						right 10px
						top 50%
						margin-top -4px
						transition all ease 400ms
					}
					br {
						display none
						@media $win768 {

						}
					}
					@media $win768 {
						font-size 2.4rem
						padding-right 0px
						&:after {
							display none
						}
					}
				}
				p {
					display none
					@media $win768 {
						display block
					}
				}
				&.active {
					> div {
						color #fff
						&:after {
							border-top-color #fff
							transform rotate(180deg)
						}
						@media $win768 {
							color inherit
						}
					}
					p {
						display block
					}
				}
				&:first-child {
					border-top 0
					padding-top 0
					margin-top 0
				}
				@media $win768 {
					font-size 1.5rem
					width 50%
					border-top 0
					padding-top 0
					margin-top 0
					padding-bottom 20px
					padding-right 20px
				}
				@media $win1280 {
					width 260px
				}
			}
		}
		.formBlock {
			padding 10px 10px 30px
			background #fff
			border-radius 15px
			max-width 1430px
			margin 0 auto
			&-steps {
				color #1D1D1D
				font-family: 'RF Dewi Expanded';
				font-size 1rem
				line-height 1.4
				margin-bottom 20px
				span {
					color #B4B4B4
					&:before {
						content ''
						display inline-block
						vertical-align text-bottom
						margin-right 4px
						border-radius 50%
						width 12px
						height 12px
						background #fff
						border 1px solid #B4B4B4
						@media $win768 {
							width 18px
							height 18px
							margin-right 10px
						}
					}
					&.active {
						color #1D1D1D
						&:before {
							border-color transparent
							background #F4D34F
						}
					}
					&:first-child {
						&:before {
							background #F4D34F
						}
						&.active1 {
							&:before {
								background #F4D34F url("../i/okArr2.svg") center center no-repeat
							}
						}
					}
					&:last-child {
						&:before {
							background url("../i/okArr1.svg") center center no-repeat
						}
						&.active {
							&:before {
								background #F4D34F
							}
						}
					}
				}
				@media $win768 {
					font-size 1.6rem
					margin-bottom: 30px;
				}
				@media $win1024 {
					margin-bottom: 50px;
				}
				@media $win1280 {
					margin-bottom: 40px;
				}
			}
			&-body {
				color #1D1D1D
			}
			&-title {
				font-family: 'RF Dewi Expanded Sbold';
				font-size 1.4rem
				line-height 1
				margin-bottom 20px
				@media $win768 {
					font-size 2.7rem
					margin-bottom 34px
				}
				@media $win1024 {
					font-size 3.3rem
					margin-bottom 30px
				}
				@media $win1280 {
					margin-bottom 45px
				}
			}
			&-items {
				display flex
				flex-wrap wrap
				justify-content space-between
				font-size 1.1rem
				line-height 1.4rem
				@media $win768 {
					font-size 1.5rem
					line-height 2.8rem
				}
				@media $win1024 {
					font-size 1.3rem
				}
				@media $win1280 {
					font-size 1.6rem
				}
			}
			&-item {
				width calc(50% - 10px)
				text-align center
				background: #FFFFFF;
				box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
				border: 1.5px solid transparent;
				border-radius: 10px;
				margin 10px 0
				padding 14px 10px
				input {
					display none
				}
				*:last-child {
					span {
						display block
						@media $win768 {
							display inline
						}
					}
				}
				img {
					height 70px
					margin-bottom 5px
					@media $win768 {
						height 126px
					}
					@media $win1024 {
						height 90px
					}
					@media $win1280 {
						height 127px
					}
				}
				&.selected {
					border: 2px solid #F4D34F;
				}
				@media $win768 {
					margin 20px 0
					border-radius: 20px;
					width calc(50% - 20px)
				}
				@media $win1024 {
					width calc(25% - 10px)
					margin 18px 0
				}
				@media $win1280 {
					width calc(25% - 30px)

					margin-bottom 0
				}
			}
			&-when {
				border-top 2px solid #D9D9D9
				padding-top 18px
				font-size 1.4rem
				margin-top 25px
				&_type {
					margin-bottom 14px
					@media $win1024 {
						margin-bottom 0
					}
				}
				label {
					display block
					padding-bottom 5px
					font-family: 'RF Dewi Expanded Sbold';
					span {
						color #FBCB21
					}
					@media $win1024 {
						padding-bottom 10px
					}
					@media $win1280 {
						padding-bottom  10px
					}
				}
				input, select {
					font-size 1.4rem
					margin-bottom 0
					appearance: none;
					-webkit-appearance: none;
					-moz-appearance: none;
					line-height 1
					@media $win768 {
						font-size 1.6rem
						min-height 75px
					}
					@media $win1280 {
						//min-height 70px
					}
				}
				> div {
					@media $win768 {
						width calc(50% - 20px)
					}
					@media $win1024 {
						width calc(50% - 5px)
					}
					@media $win1280 {
						width calc(50% - 20px)
					}
				}
				@media $win768 {
					font-size 2rem
					display flex
					justify-content space-between
					margin-top 28px
				}
				@media $win1024 {
					font-size 1.8rem
					margin-top 40px
					padding-top 25px
				}
				@media $win1280 {
					font-size 2rem
					margin-top 50px
					padding-top 40px
				}
			}
			&-panels {
			}
			&-panel {
				width 100%
				background #ebebeb
				padding 19px 15px
				border-radius 10px
				margin-top 10px
				margin-bottom 5px
				display none
				position: relative;
				&_textarea {
					label {
						display block
						font-size 1.3rem
						line-height 1.8rem
						padding-bottom 10px
						font-family: 'RF Dewi Expanded Sbold';
						max-width 200px
						@media $win768 {
							font-size 1.5rem
							padding-bottom 15px
							max-width inherit
						}
						@media $win1024 {
							font-size 1.8rem
							padding-bottom 15px
						}
						@media $win1280 {
							font-size 2rem
						}
					}
					.textarea {
						min-height 40px
						height auto
						background #fff
						padding 20px
						color #1D1D1D
						font-size 1.3rem
						margin-bottom 0
						transition all ease 500ms
						&:focus {
							//height 160px
						}
						@media $win768 {
							font-size 1.6rem
							line-height 2.8rem
						}
					}
					&.d-size {
						label {
							font-size 1.5rem
							max-width inherit
							@media $win1024 {
								font-size 1.8rem
							}
							@media $win1280 {
								font-size 2rem
							}
						}
						.textarea {
							font-size 1.4rem
							@media $win768 {
								font-size 1.6rem
								line-height 2.8rem
							}
							@media $win1024 {

							}
						}
					}
				}
				&_items {
					@media $win768 {
						display flex
						justify-content space-between
					}
				}
				&_item {
					background #fff
					border-radius 10px
					padding 15px
					margin-bottom 24px
					&-top {
						padding-top 10px
						display flex
						justify-content center
						font-size 1.1rem
						//font-weight 600
						> div {
							width 50%
							padding-left 10px
							&:first-child {
								text-align center
								padding-right 10px
								img {
									@media $win768 {
										width 84px
									}
									@media $win1024 {
										width 88px
									}
								}
								@media $win768 {
									height 98px
									display flex
									align-items center
									justify-content center
								}
								@media $win1024 {
									height 90px
									width 45%
									padding-right 20px
									padding-left 0
								}
							}
							> div {
								font-family: 'RF Dewi Expanded Sbold';
							}
							@media $win768 {
								width 100%
							}
							@media $win1024 {
								width 55%
								padding-left 15px
							}
							@media $win1280 {
								padding-left 25px
							}
						}
						@media $win768 {
							display block
							font-size 1.5rem
							line-height 2.4rem
							text-align center
						}
						@media $win1024 {
							display flex
							text-align left
							font-size 1.4rem
							line-height 2.4rem
							padding-top 16px
							align-items center
						}
						@media $win1280 {
							font-size 1.6rem
						}
					}
					&-qt {
						padding-top 16px
						&_title {
							font-family: 'RF Dewi Expanded Sbold';
							padding-bottom 5px
							font-size 1.4rem
							@media $win768 {
								font-size 2rem
								text-align center
							}
							@media $win1024 {
								text-align left
								padding-bottom 10px
							}
						}
						&_nums {
							border 2px solid #D9D9D9
							box-sizing border-box
							border-radius 10px
							position relative
							display flex
							align-items center
							padding 12px 10px
							justify-content space-between
							span {
								display block
								color #9E9E9E
								font-size 0
								width 20px
								height 20px
								text-align center
								background url("../i/minus.svg") center center no-repeat
								background-size 12px auto
								@media $win768 {
									background-size 15px auto
									width 30px
								}
								@media $win1280 {
									background-size 20px auto
									width 50px
								}
							}
							*:last-child {
								background-image url("../i/plus.svg")
							}
							input {
								border 0
								text-align center
								width 100%
								font-size 1.1rem
								padding 10px 0
								@media $win768 {
									font-size 1.6rem
									padding 12px 0
								}
								@media $win1024 {
									font-size 1.6rem
								}
								@media $win1280 {
									font-size 2rem
								}
							}
							@media $win1280 {
								border-radius 13px
							}
						}
						@media $win1024 {
							padding-bottom 6px
						}
						@media $win1280 {
							padding-top 30px
						}
					}
					&:nth-child(0) {
						img {

						}
					}
					@media $win768 {
						width 200px
						width calc(100%/3 - 5px)
						border-radius 15px
					}
					@media $win1024 {
						//width 266px
						width calc(100%/3 - 4px)
					}
					@media $win1280 {
						width calc(100%/3 - 6px)
						padding 15px 40px 25px
						margin-bottom: 32px;
					}
				}
				&:after {
					content ''
					display block
					position absolute
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-bottom: 10px solid #ebebeb
					top -10px
					@media $win768 {
						border-left: 18px solid transparent;
						border-right: 18px solid transparent;
						border-bottom: 18px solid #ebebeb
						top -18px
					}
				}
				&[data-panel="0"]:after {
					left calc(25% - 14px)
					@media $win768 {
						left calc(25% - 24px)
					}
					@media $win1024 {
						left 85px
					}
					@media $win1280 {
						left 115px
					}
				}
				&[data-panel="1"]:after {
					left auto
					right calc(25% - 14px)
					@media $win768 {
						right calc(25% - 24px)
					}
					@media $win1024 {
						left 300px
						right auto
					}
					@media $win1280 {
						left 410px
					}
				}
				&[data-panel="2"]:after {
					left calc(25% - 14px)
					@media $win768 {
						left calc(25% - 24px)
					}
					@media $win1024 {
						left 520px
					}
					@media $win1280 {
						left 710px
					}
				}
				&[data-panel="3"]:after {
					left auto
					right calc(25% - 14px)
					@media $win768 {
						right calc(75% - 24px)
					}
					@media $win1024 {
						left 740px
						right auto
					}
					@media $win1280 {
						left 1010px
					}
				}
				&.boxes {
					padding 15px
					margin-top 10px
					@media $win768 {
						margin-top 24px
						padding 18px 16px
					}
					@media $win1024 {
						margin-top 22px
						padding 23px
					}
					@media $win1280 {
						margin-top 42px
						padding 30px
					}
				}
				@media $win768 {
					border-radius 20px
					margin-top 22px
					padding-top: 15px;
					padding-bottom 15px
				}
				@media $win1024 {
					margin-bottom 0px
					border-radius 10px
					padding 20px 25px 25px
				}
				@media $win1280 {
					margin-top 40px
					padding 30px
					border-radius 20px
				}
			}
			&-btn {
				text-align right
				padding-top 50px
				.d-btn {
					width auto
					min-width 140px
					@media $win768 {
						min-width 190px
					}
					@media $win1024 {
						min-width 260px
					}
				}
				&.twoBtn {
					text-align center
					display flex
					flex-direction row
					justify-content space-between
					padding-top 35px
					.d-btn {
						font-size 1.4rem
						padding-left 0
						padding-right 0
						//min-width 200px
						&.backBtn {
							@media $win768 {
								margin-bottom 0
							}
						}
						@media $win768 {
							font-size 2rem
							min-width 260px
						}
					}
					@media $win768 {
						display flex
						justify-content space-between
						padding-top 50px
					}
					@media $win1280 {
						padding-top 40px
					}
				}
				@media $win1024 {
					padding-top 90px
				}
				@media $win1280 {
					padding-top 70px
				}
			}
			&-last {
				&_item {
					label {
						font-family: 'RF Dewi Expanded Sbold';
						font-size 1.4rem
						padding-bottom 5px
						display block
						span {
							color #fbcb21;
						}
						@media $win768 {
							font-size 2rem
							padding-bottom 10px
						}
						@media $win1024 {
							font-size 1.8rem
						}
						@media $win1280 {
							font-size 2rem
						}
					}
					input {
						@media $win768 {
							margin-bottom 18px
						}
						@media $win1024 {
							margin-bottom 30px
						}
					}
					@media $win1024 {
						width calc(50% - 5px)
					}
					@media $win1280 {
						width calc(50% - 20px)
					}
				}
				@media $win1024 {
					display flex
					flex-wrap wrap
					justify-content space-between
				}
			}
			@media $win321 {
				padding 20px 20px 50px
			}
			@media $win414 {
				padding 20px 20px 50px
			}
			@media $win768 {
				padding 30px 40px 70px
				border-radius 25px
			}
			@media $win1024 {
				border-radius 40px
				padding-top 40px
				padding-bottom 90px
			}
			@media $win1280 {
				border-radius 50px
				padding-bottom 70px
			}
		}

		@media $win768 {
			//padding 50px 45px 60px
			padding 50px 20px 60px
			background #0F2B70 url("../i/topBg.svg") calc(50% + 200px) 80px no-repeat
			background-size auto 300px
			border-radius 35px
			&.formPage {
				background #0F2B70 !important;
			}
		}
		@media $win1024 {
			background-size auto 420px
			background-position calc(50% + 280px) 70px
			padding 58px 25px 60px
		}
		@media $win1280 {
			padding 65px 25px 60px
			background-position calc(50% + 380px) 75px
			border-radius 25px
		}
	}


	/*- six -*/

	.six {
		padding-top 50px
		text-align center
		.inner {
			max-width 1120px
			margin 0 auto
			padding 0 10px
			.title {

			}
			@media $win768 {
				padding 0 15px
			}
		}
		&-items {
			padding-top 30px
			@media $win768 {
				display flex
				flex-direction row
				flex-wrap wrap
				padding-top 50px
				justify-content space-between
			}
			@media $win1024 {
				max-width 740px
				margin 0 auto
			}
			@media $win1280 {
				max-width 1100px
			}
		}
		&-item {
			font-size 1.3rem
			margin-top 20px
			> * {
				display block
			}
			> span {
				img {
					width auto
					height 220px
				}
			}
			> div {
				color #0F2B70
				font-family: 'RF Dewi Expanded Sbold';
				font-size 1.5rem
				line-height 1.2
				@media $win768 {
					font-size 2.4rem
					margin-top 0px
				}
			}
			> p {
				font-weight 500
			}
			@media $win768 {
				width 50%
				margin-top 50px
				padding 0 10px
			}
			@media $win1024 {
				padding 0 20px
			}
			@media $win1280 {
				width 30%
				font-size 1.5rem
			}
		}

		&-btn {
			margin-top 40px
			font-size 1.5rem
			br {
				display none
			}
			@media $win768 {
				margin-top 100px
				br {
					display none
				}
			}
		}
		@media $win768 {
			padding-top 120px
			padding-bottom 120px
		}
	}

	/*- removalRequest -*/
	.removalRequest {
		padding-top 100px
		.inner {

		}
		&-img {
			width 100%
			position relative
			max-width: 1260px;
			margin: 0 auto;
			&:before {
				content ''
				display block
				border-radius 35px
				width 100%
				height 0
				padding-bottom 58.82352941176471%
				background url("../i/requestBgM.png") center center no-repeat
				background-size contain
				box-shadow: 0px 30px 70px -20px rgba(0, 0, 0, 0.25);
				@media $win768 {
					background url("../i/requestBg.jpg") center center no-repeat
					padding-bottom 0
					height 500px
				}
			}
			> div {
				position: absolute;
				padding 20px 15px
				left 0
				top 0
				display flex
				flex-direction column
				justify-content space-between
				height 100%
				width 100%
				@media $win768 {
					justify-content center

				}
				@media $win1024 {
					padding 20px 0
					left 50%
					max-width 960px
					margin-left -480px
				}
			}
		}
		&-title {
			font-family: 'RF Dewi Expanded Sbold';
			font-size 1.6rem
			line-height 1.2
			max-width 200px
			padding-top 20px
			@media $win768 {
				font-size 3.3rem
				line-height normal
				max-width 400px
				padding-bottom 40px
			}
			@media $win1024 {
				font-size 4rem
				max-width 460px
			}
		}
		&-btn {
			text-align center
			@media $win768 {
				text-align left
			}
		}
		&-items {
			padding 50px 10px 0
			font-weight 400
			font-size 1.3rem
			@media $win768 {
				display flex
				flex-direction row
				justify-content space-between
				font-size 1.6rem
				border-bottom 1px solid #C4C4C4
				padding-bottom 60px
				max-width 960px
				margin 0 auto
				padding 50px 15px 0
			}
			@media $win1024 {
				padding 50px 0
			}
		}
		&-item {
			padding 20px 0px
			> div {
				font-size 1.6rem
				font-family: 'RF Dewi Expanded Sbold';
				@media $win768 {
					font-size 2rem
					line-height normal
				}
			}
			p {
				margin-top 15px
			}
			&:last-child {
				border-bottom 1px solid #C4C4C4
				@media $win768 {
					border-bottom 0
				}
			}
			@media $win768 {
				width calc(50% - 30px)
				//padding-right 60px
			}
			@media $win1024 {
				width 460px
			}
		}

		@media $win768 {
			padding-top 0px
		}
	}

	/*- serts -*/
	.serts {
		padding 50px 10px
		font-size 1.3rem
		.inner {
			@media $win768 {
				display flex
				flex-direction row
				justify-content space-between
				align-items center
				max-width 960px
				margin 0 auto
			}
		}
		&-img {
			width 100%
			max-width 410px
			margin 0 auto 40px
			&_wrapper {
				position: relative;
				width 100%
				height 0
				padding-bottom 84%
				> div {
					position absolute
					width 80%
					height auto
					box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.25);
				}
			}
			&_first {
				left 0
				top 0
				z-index 2
			}
			&_second {
				right 0
				bottom 0
				z-index 1
			}
			img {
				width 100%
				height auto
				vertical-align top
			}
			@media $win768 {
				width calc(50% - 30px)
				//padding-right 60px
				margin 0
			}
		}
		&-text {
			font-weight 400
			> div {
				font-size 1.6rem
				line-height 1.2
				font-family: 'RF Dewi Expanded Sbold';
				@media $win768 {
					font-size 2rem
					line-height normal
				}
			}
			@media $win768 {
				width calc(50% - 30px)
				//padding-right 60px
			}
			@media $win1024 {
				width 460px
			}
		}
		@media $win768 {
			padding 60px 15px 120px
			font-size 1.7rem
		}
	}

	/*- -*/
	.bottomForm {
		border-radius 15px
		color #fff
		padding 50px 10px 80px
		background #0F2B70
		text-align center

		.inner {
			@media $win768 {
				max-width 600px
				margin 0 auto
			}
		}

		&-title {
			font-family: 'RF Dewi Expanded Sbold';
			font-size 2.5rem
			line-height normal

			& + p {
				display block
				font-family: 'RF Dewi Expanded Sbold';
				font-size 1.6rem
				line-height 1
				@media $win768 {
					font-size 2.4rem
					padding 0 60px
				}
			}
			@media $win768 {
				font-size 4rem
			}
		}

		form {
			padding-top 20px

			&:after {
				content ''
				display inline-block
				background url("../i/logoOnly.svg") center center no-repeat
				background-size auto 100%;
				min-height 64px
				font-size 0
				width 112px
				margin-top 70px
			}
			max-width 420px
			margin 0 auto

			.d-btn {
				margin-top 15px
			}
			@media $win768 {
				max-width 100%
			}
		}
		@media $win768 {
			padding 100px 25px 100px
			background #0F2B70
			border-radius 35px
		}
	}

	.fake-bg {
		position: fixed;
		left 0
		top 0
		width 100%
		height 100%
		background rgba(0,0,0,.5)
		z-index: 2;
	}
